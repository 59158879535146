<template class="body">
  <div>
    <div>
      <p-multiselect
        ref="control"
        :key="+new Date()"
        placeholder="Search for Workflow / Template / Step"
        no-options-text="Nothing found to meet your search criteria"
        label="Workflows"
        :value="value.map(v => ({ label: v.title, value: v }))"
        :filter-results="false"
        :search-change-callback="suggestAsync"
        :taggable="true"
        :object="true"
        :create-option="false"
        :limit="0"
        :loading="loading"
        :close-on-select="false"
        @change="change"
        @update:value="$emit('update:value', $event)"
        @keydown.ctrl.a.prevent="$refs.control.selectAll()"
      />
    </div>
    <div class="announcement">
      <span>Ctrl+A to select all found entries</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Multiselect from '@/components/common/Multiselect';
export default {
  components: {
    'p-multiselect': Multiselect
  },
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'update:value'],
  computed: {
    ...mapState({
      loading: s => s.workflows.taxonomy.loading
    })
  },
  async created() {
    await this.$store.dispatch('workflows/taxonomy');
  },
  async unmounted() {
    await this.$store.dispatch('workflows/resetTaxonomy');
  },

  methods: {
    change(option) {
      this.$emit(
        'change',
        option.map(o => o.value)
      );
    },
    async suggestAsync(q) {
      const result = q?.length ? await this.$store.getters['workflows/taxonomy'](q) : [];

      return result.map(e => {
        return {
          label: e.title,
          value: e
        };
      });
    }
  }
};
</script>
<style scoped>
.body {
  display: flex;
}
.announcement {
  display: flex;
  justify-content: right;
  font-size: 0.7rem;
  font-style: italic;
  padding-top: 3px;
  opacity: 0.8;
}
</style>
