export const statusChangeToText = (template, stepId, status, asHtml, isBotTask) => {
  if (!template) {
    return;
  }
  if (!stepId) {
    return;
  }

  const outgoingPaths = template.paths.filter(p => p.from.step === stepId && status === p.from.port);
  if (!outgoingPaths.length && !isBotTask) {
    return;
  }

  const output = outgoingPaths.reduce((acc, path) => {
    if (path.from.action) {
      if (path.from.action.form) {
        const result = `Prompt to fill out "${path.from.action.form.name}" form`;
        if (!acc.includes(result)) {
          acc.push(result);
        }
      }
    }
    if (path.to.port === 'nowhere') {
      // do nothing.
    } else if (path.to.port === 'finish') {
      acc.push('finish milestone');
    } else {
      const nextTaskTemplate = template.steps.find(step => step.id === path.to.step);

      acc.push(`Mark the "${nextTaskTemplate && nextTaskTemplate.title}" task as "${path.to.port}"`);
    }

    return acc;
  }, []);

  if (isBotTask) {
    output.push('Start a bot');
  }

  if (asHtml) {
    const result = `<h5>Clicking here will:</h5>${output.map(string => `<div>-- ${string}</div>`).join('')}`;

    return result;
  }
  return output.join(', ');
};

export const statusOrder = {
  'to do': 1,
  'in progress': 2,
  done: 3,
  cancelled: 4,
  'blocked ': 5,
  'on hold': 6,
  'sent to 2AR': 7,
  air: 8,
  rejected: 9
};

export default {
  statusChangeToText,
  statusOrder
};
