const createPorts = (statuses, suffix) => {
  return statuses.reduce((i, status) => {
    i[`${status.port ? status.port : status}____${suffix}`] = { connections: [] };
    return i;
  }, {});
};

const createOutputs = statuses => {
  return createPorts(statuses, 'output');
};

const createInputs = statuses => {
  return createPorts(statuses, 'input');
};

const createPaths = (paths = [], inputs, outputs, itemId) => {
  paths
    .filter(step => step.to.step === itemId)
    .reduce((inpAcc, path) => {
      const key = `in____input`;
      inpAcc[key].connections.push({
        data: {
          pins: path.display ? path.display.pins : []
        },
        node: path.from.step,
        output: `out____output`
      });
      return inpAcc;
    }, inputs);
  paths
    .filter(step => step.from.step === itemId)
    .reduce((outAcc, path) => {
      const key = `out____output`;
      outAcc[key].connections.push({
        data: {
          pins: path.display ? path.display.pins : []
        },
        node: path.to.step,
        input: `in____input`
      });
      return outAcc;
    }, outputs);
};

const fillPathsWithData = (paths = [], nodes) => {
  paths.forEach(path => {
    if (path.to.action) {
      const inputTarget = nodes[path.to.step];
      const targetConnections = inputTarget.inputs[`${path.to.port}____input`].connections;
      const targetConnection = targetConnections.find(c => {
        return c.node === path.from.step && c.output === `${path.from.port}____output`;
      });
      targetConnection.data.to = path.to.action;

      const oututTarget = nodes[path.from.step];
      const outTargetConnections = oututTarget.outputs[`${path.from.port}____output`].connections;
      const outTargetConnection = outTargetConnections.find(c => {
        return c.node === path.to.step && c.input === `${path.to.port}____input`;
      });
      outTargetConnection.data.to = path.to.action;
    }
    if (path.from.action) {
      const a = nodes[path.from.step];
      const b = a.outputs[`${path.from.port}____output`].connections;
      const o = b.find(c => {
        return c.node === path.to.step && c.input === `${path.to.port}____input`;
      });

      o.data.from = path.from.action.form.properties;

      const g = nodes[path.to.step];
      const h = g.inputs[`${path.to.port}____input`].connections;

      const op = h.find(c => {
        return c.node === path.from.step && c.output === `${path.from.port}____output`;
      });
      op.data.from = path.from.action.form.properties;
    }
  });
};

const importData = ({ steps = [], paths = [], eventSources = [], roadmap = [] }, onItemClick, createdSteps) => {
  const nodes = steps.reduce((acc, curr) => {
    const inputs = createInputs(['in']);
    const outputs = createOutputs(['out']);
    createPaths(paths, inputs, outputs, curr.id);

    const step = {
      id: curr.id,
      data: {
        props: {
          type: 'generic',
          title: curr.title,
          source: 'workflow',
          onItemClick,
          clickable: curr.type === 'finish' || curr.type === 'and' ? false : !createdSteps.includes(curr.id)
        }
      },
      name: 'generic',
      inputs,
      outputs,
      position: curr.display.position
    };
    acc[curr.id] = step;
    return acc;
  }, {});

  if (eventSources) {
    eventSources.forEach(eventSource => {
      const outputs = createOutputs(['out']);
      createPaths(paths, {}, outputs, eventSource.id);
      const step = {
        id: eventSource.id,
        data: {
          props: {
            type: eventSource.type,
            title: eventSource.title,

            clickable: false
          }
        },
        name: 'generic',
        outputs,
        position: eventSource.display.position
      };
      nodes[eventSource.id] = step;
    });
  }
  return {
    id: 'demo@0.1.0',
    nodes
  };
};
const payload = { importData };

export default payload;
