<template>
  <Popper>
    <div class="relative-date-picker">
      <span class="text">{{ textRepresentation }} </span>
      <Icon name="calendar-month-outline" />
    </div>
    <template #content>
      <div class="relative-date-picker-popover">
        <div class="row">
          <div class="title">Ref date</div>
          <Multiselect v-model:value="refDate" :options="refDateOptions"
            @input="$event => onFieldChanged('refDate', $event)" />
        </div>
        <div class="row">
          <div class="title">Offset</div>
          <TextField v-model="offset" type="number" @change="onFieldChanged('offset', $event)" />
        </div>
        <div class="row">
          <div class="title">Units</div>
          <MultipleToggleButton :options="['day', 'week', 'month']" :initial-selected="unit"
            @changed="onFieldChanged('unit', $event)" />
        </div>
      </div>
    </template>
  </Popper>
</template>

<script>
import Icon from './Icon';
import Popper from 'vue3-popper';
import Multiselect from './Multiselect.vue';
import TextField from './TextField.vue';
import MultipleToggleButton from './MultipleToggleButton.vue';
export default {
  components: {
    Icon,
    Popper,
    Multiselect,
    TextField,
    MultipleToggleButton
  },
  props: {
    modelValue: {
      type: Object,
      default: () => null
    }
  },
  emits: ['update:modelValue'],
  data() {
    const refDateOptions = [
      'Today',
      'Start of this week',
      'Start of this month',
      'Start of this year',
      'End of this week',
      'End of this month',
      'End of this year'
    ];
    return {
      refDate: this.modelValue ? this.modelValue.refDate : refDateOptions[0],
      refDateOptions,
      offset: this.modelValue ? this.modelValue.offset : 0,
      unit: this.modelValue ? this.modelValue.unit : 'day'
    };
  },
  computed: {
    textRepresentation() {
      if (!this.refDate) {
        return 'Select a date';
      }

      if (!this.unit || !+this.offset) {
        return this.refDate;
      }
      const sign = this.offset > 0 ? 'plus' : 'minus';
      const unit = `${this.unit}${Math.abs(this.offset) > 1 ? 's' : ''}`;
      return ` ${this.refDate} ${sign} ${Math.abs(this.offset)} ${unit}`;
    }
  },
  methods: {
    onFieldChanged(field, value) {
      this[field] = value;
      this.$emit('update:modelValue', {
        refDate: this.refDate,
        offset: this.offset,
        unit: this.unit
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.relative-date-picker {
  width: 100%;
  height: 35px;
  border: 1px solid var(--theme-on-background-accent);
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 5px;
  padding: 0 5px;
  align-items: center;

  .text {
    font-size: 10px;
  }
}

.relative-date-picker-popover {
  width: 300px;

  .row {
    margin: 15px 0;
  }
}
</style>
