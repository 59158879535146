<template>
  <hub-modal :visible="true">
    <template #title>
      <span>{{ mode }} people</span>
    </template>
    <div class="mass-edit-assignees-modal">
      <div class="form-row">
        <p-button v-if="!assignees.includes(email)" variant="text" class="claim-button" color="primary" @click="addMe"> {{ mode }} me </p-button>
        <p-assignees v-model:value="assignees" placeholder="Select assignees" />
      </div>
    </div>
    <template #footer>
      <div class="footer-buttons">
        <p-button variant="text" @click="$emit('close')">Cancel</p-button>
        <p-button :disabled="!assignees.length" style="justify-self: start" type="button" color="primary" @click="$emit('submit', assignees)">{{
          mode
        }}</p-button>
      </div>
    </template>
  </hub-modal>
</template>
<script>
import { mapState } from 'vuex';
import Modal from '@/components/common/Modal';
import Assignees from '@/components/Assignees';
import Button from '@/components/common/Button';

export default {
  components: {
    'p-button': Button,
    'hub-modal': Modal,
    'p-assignees': Assignees
  },
  props: {
    mode: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'submit'],
  data() {
    return {
      assignees: []
    };
  },
  computed: {
    ...mapState({
      email: s => s.identity.email
    })
  },
  methods: {
    addMe() {
      this.assignees = [...this.assignees, this.email];
    }
  }
};
</script>
<style lang="scss" scoped>
.mass-edit-assignees-modal {
  padding: 25px;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: minmax(0, 1fr);
  .form-row {
    position: relative;
    .claim-button {
      position: absolute;
      right: 0;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: flex-end;
      font-size: 0.7rem;
      cursor: pointer;
      top: -20px;
    }
  }
}
</style>
