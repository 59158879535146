<template>
  <li class="list-item" :class="[status.split(' ').join('-')]" @click="$event => $emit('click', $event)">
    <hub-reference-list :invention="invention" />
  </li>
</template>

<script>
import ReferenceList from '@/components/common/ReferenceList';

export default {
  components: {
    'hub-reference-list': ReferenceList
  },
  props: {
    id: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    invention: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      default: true
    }
  },
  emits: ['click']
};
</script>

<style lang="scss" scoped>
.list-item {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: max-content;
  grid-gap: 0.25rem;
  line-height: 1.2;
  border-radius: 1px;
  font-size: 0.75rem;
  min-height: 1.75rem;
  align-items: center;
  margin-bottom: 0.25rem;

  padding: 0.5rem 0.75rem;
  border: 1px solid transparent;
  transition: 0.25s box-shadow;
  position: relative;

  &.selectable {
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 0 1px var(--theme-highlight);
    }

    > * {
      cursor: pointer !important;
    }
  }
}
</style>
