<template>
  <section class="task-filter">
    <section>
      <header>
        <h5>Search</h5>
      </header>
      <div class="form-row">
        <hub-text-field v-model="qs" autocomplete="off" :rows="3" @input="search" />
      </div>
    </section>
    <hr />
    <section>
      <header>
        <h5>Filter</h5>
      </header>
      <form class="filter-form" @submit.stop.prevent="search">
        <div class="form-row">
          <hub-multiselect :value="dueAt" label="Due at" placeholder="" :options="options.dueAt" :searchable="false"
            :can-clear="false" :can-deselect="false" @change="props => applyDueAt(props)" />
          <div style="padding: 0 10px">
            <RelativeDatePicker v-if="customControlShown" :from="dueAtCustom.from" :to="dueAtCustom.to"
              @input="onCustomDueAtChange" />
          </div>
        </div>

        <div class="form-row">
          <div v-if="isGetStatusCollectionPending" style="place-self: center">
            <hub-icon name="loading" spin size="lg"></hub-icon>
          </div>
          <hub-checklist v-else :value="status" label="Status" :test-id="'filter-statuses'" :options="statuses"
            @update="props => applyChecklistFiltering('status', props)" />
        </div>
        <div class="form-row two-column">
          <div class="first-column">
            <div class="add-me-wrapper">
              <Button v-if="!assignees.includes('@me')" variant="text" color="primary" @click="adMe('assignees')"> add @me
              </Button>
            </div>
            <hub-assignees :value="assignees" label="Assigned to" :test-id="'task-assigned-to'" placeholder=""
              @change="props => applyTagsFiltering('assignees', props)" />
          </div>
          <div class="second-column">
            <hub-toggle :options="['or', 'and']" :initial-selected="assigneesConjugation" :capitalize="true"
              @changed="option => applyMultiselectFiltering('assigneesConjugation', option)" />
          </div>
        </div>
        <div class="form-row two-column">
          <div class="first-column">
            <div class="add-me-wrapper">
              <Button v-if="!projectAssignees.includes('@me')" variant="text" color="primary"
                @click="adMe('projectAssignees')"> add @me </Button>
            </div>
            <hub-assignees :value="projectAssignees" label="Responsible Attorney" :test-id="'task-assignees-resp-att'"
              placeholder="" @change="props => applyTagsFiltering('projectAssignees', props)" />
          </div>
        </div>
        <div class="form-row two-column">
          <div class="first-column">
            <hub-multiselect :value="tags" :taggable="true" :multiple="true" :create-option="false" label="Tags"
              placeholder="Milestone template tags" :options="avaliableTags.collection"
              @change="props => applyMultiselectFiltering('tags', props)" />
          </div>
          <div class="second-column">
            <hub-toggle :options="['or', 'and']" :initial-selected="tagsConjugation" :capitalize="true"
              @changed="option => applyMultiselectFiltering('tagsConjugation', option)" />
          </div>
        </div>
        <div class="form-row">
          <hub-worklfows :value="workflows" label="Workflows" :test-id="'task-workflows'" placeholder=""
            @change="props => applyWorkflowFiltering(props)" />
        </div>
        <div class="form-row">
          <Checkbox :value="catchAll" label="Exclude tasks already on this board"
            @change="() => applyMultiselectFiltering('catchAll', !catchAll)" />
        </div>
      </form>
    </section>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import TextField from '@/components/common/TextField';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import Checklist from '@/components/common/Checklist';
import Assignees from '@/components/Assignees';
import Wokrflows from './WorkflowFilter.vue';
import Toggle from '@/components/common/MultipleToggleButton';
import Checkbox from '@/components/common/Checkbox';
import RelativeDatePicker from '@/components/common/RelativeDatePickerRange';

import Multiselect from '@/components/common/Multiselect';
import dueAt from '@/components/common/dueAt';

export default {
  components: {
    'hub-text-field': TextField,
    'hub-multiselect': Multiselect,
    'hub-checklist': Checklist,
    'hub-assignees': Assignees,
    'hub-icon': Icon,
    'hub-worklfows': Wokrflows,
    'hub-toggle': Toggle,
    Button,
    Checkbox,
    RelativeDatePicker
  },
  props: {
    filter: {
      required: false,
      default: () => null,
      type: Object
    }
  },
  emits: ['changed'],
  data() {
    const options = {
      dueAt: [
        {
          label: 'Show all',
          value: ''
        },
        ...dueAt().map(s => ({
          label: s.name[0].toUpperCase() + s.name.slice(1),
          value: s.name
        })),
        {
          label: 'Custom range',
          value: 'custom'
        }
      ]
    };
    return {
      qs: '',
      dueAt: '',
      status: [],
      assignees: [],
      projectAssignees: [],
      workflows: [],
      tags: [],
      assigneesConjugation: 'or',
      tagsConjugation: 'or',
      catchAll: false,
      customControlShown: false,
      dueAtCustom: {
        from: {
          refDate: 'Start of this week'
        },
        to: {
          refDate: 'End of this week'
        }
      },
      options
    };
  },
  computed: {
    ...mapState({
      statuses: s => s.tasks.statusCollection,
      avaliableTags: s => s.workflows.tags,
      isGetStatusCollectionPending: s => s.tasks.isGetStatusCollectionPending
    }),
    ...mapGetters({
      filters: 'filters/all'
    })
  },
  created() {
    this.$store.dispatch('workflows/tags');
    this.initialize();
  },

  methods: {
    async initialize() {
      if (!this.filter) {
        return;
      }

      if (typeof this.filter.dueAt === 'string') {
        this.dueAt = this.filter.dueAt;
      } else if (this.filter.dueAt && (this.filter.dueAt.from || this.filter.dueAt.to)) {

        this.dueAtCustom = this.filter.dueAt;
        this.dueAt = 'custom';
        this.customControlShown = true;
      } else {
        this.dueAt = this.options.dueAt[0].value;
      }
      this.qs = this.filter.qs;
      this.status = this.filter.status;
      this.assignees = this.filter.assignees?.filter(a => a) || [];
      this.projectAssignees = this.filter.projectAssignees?.filter(a => a) || [];
      this.assigneesConjugation = this.filter.assigneesConjugation || 'or';
      this.tags = this.filter.tags || [];
      this.tagsConjugation = this.filter.tagsConjugation || 'or';
      this.catchAll = this.filter.catchAll || false;

      this.workflows = this.filter.workflows || [];
    },
    applyDueAt(props) {
      this.dueAt = props;

      if (props === 'custom') {
        this.customControlShown = true;
      }
      this.filterChanged();
    },

    onCustomDueAtChange(props) {
      this.dueAtCustom = props;
      this.filterChanged();
    },
    search(props) {
      this.filterChanged();
      this.$trackEvent(`Task list searched`);
    },
    applyMultiselectFiltering(fieldName, props) {
      this[fieldName] = props;
      this.filterChanged();
      this.$trackEvent(`Task list filtered by '${fieldName}'`);
    },
    applyTagsFiltering(fieldName, value) {
      this[fieldName] = value?.length ? value : [];
      this.filterChanged();
      this.$trackEvent(`Task list filtered by '${fieldName}'`);
    },
    adMe(fieldName) {
      this[fieldName] = [...this[fieldName], '@me'];
      this.applyTagsFiltering(fieldName, this[fieldName]);
    },

    applyChecklistFiltering(fieldName, props) {
      this[fieldName] = props?.length ? props : '';

      this.filterChanged();

      this.$trackEvent(`Task list filtered by '${fieldName}'`);
    },
    applyWorkflowFiltering(props) {
      this.workflows = props?.length ? props : [];

      this.filterChanged();

      this.$trackEvent(`Task list filtered by '${JSON.stringify(props)}'`);
    },

    filterChanged() {
      const { dueAt, status, assignees, projectAssignees, qs, workflows, assigneesConjugation, tags, tagsConjugation, catchAll, dueAtCustom } = this;
      const existing = {
        dueAt: dueAt === 'custom' ? this.dueAtCustom : this.dueAt,
        status,
        assignees,
        projectAssignees,
        qs,
        workflows,
        assigneesConjugation,
        tags,
        tagsConjugation,
        catchAll
      };
      this.$emit('changed', existing);
    }
  }
};
</script>

<style lang="scss" scoped>
.two-column {
  display: grid;

  grid-template-columns: 1fr max-content;

  .first-column {
    align-self: flex-start;
    margin-right: 5px;
    position: relative;

    .add-me-wrapper {
      position: absolute;
      right: 0;
    }
  }

  .second-column {
    min-width: 50px;
    margin-top: 20px;
    align-self: center;
  }
}

.task-filter {
  padding: 0.5rem;
  height: 100%;
  position: relative;
  overflow-y: scroll;

  .search-form,
  .filter-form {
    display: grid;
    grid-gap: 0.5rem;

    background: var(--theme-surface);

    .error {
      font-size: 0.8rem;
      color: var(--theme-error);
      text-align: left;
      padding: 0.25rem 0;
      display: none;
    }

    &.dirty {
      .error {
        display: block;
      }
    }
  }

  .search-form {
    grid-template-columns: minmax(0, 1fr) max-content;
    align-items: center;

    .submit {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  .filter-form {
    grid-template-columns: minmax(0, 1fr);
  }

  h5 {
    margin-bottom: 0.75rem;
  }
}
</style>
