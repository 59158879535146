<template>
  <div class="form-values">
    <div v-for="row in properties" :key="row.property" class="form-row">
      <p-text-field
        v-if="row.type === 'string' || row.type === 'number'"
        :model-value="row.value"
        :label="row.description || row.name"
        autocomplete="off"
        :disabled="true"
      />
      <hub-date
        v-if="row.type === 'date' || row.type === 'datetime'"
        :mode="row.type"
        :value="row.value"
        :label="row.description || row.name"
        :disabled="true"
      />
      <p-assignees
        v-if="row.type === 'people'"
        :value="row.value?.filter(a => a?.length)"
        :label="row.description || row.name"
        placeholder=""
        :disabled="true"
      />
      <FilePicker
        v-if="row.type === 'file'"
        :value="row.value"
        :label="row.description || row.name"
        :reference="reference"
        :test-id="row.name"
        :disabled="true"
        :multiple="true"
      />
      <FormPicker
        v-if="row.type === 'formPicker'"
        :label="row.description || row.name"
        :value="row.value"
        :client-code="clientCode"
        :disabled="true"
      />
      <EmailPicker
        v-if="row.type === 'email'"
        :label="row.description || row.name"
        :invention-id="invention.id"
        :model-value="row.value"
        :disabled="true"
      />
      <Checkbox
        v-if="row.type === 'boolean'"
        v-model:value="row.value"
        :label="row.description || row.name"
        :disabled="true"
        class="label"
      />
    </div>
  </div>
</template>
<script>
import TextField from '@/components/common/TextField';
import Assignees from '@/components/Assignees';
import DateField from '@/components/common/DateField';
import EmailPicker from '@/components/common/EmailPicker';
import FilePicker from '@/components/common/filepicker/FilePicker';
import FormPicker from '@/components/common/formpicker/FormPicker';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    'p-text-field': TextField,
    'p-assignees': Assignees,
    'hub-date': DateField,
    FilePicker,
    FormPicker,
    EmailPicker,
    Checkbox
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },
    invention: {
      type: Object,
      default: null
    }
  },
  computed: {
    reference() {
      return this.inventionValue?.references?.find(r => r.toLowerCase().startsWith('harrity:'));
    },
    inventionValue() {
      if (this.invention) {
        return this.invention;
      }
      return Array.isArray(this.data) ? null : this.data.invention;
    },
    properties() {
      return Array.isArray(this.data) ? this.data : this.data.properties;
    },
    clientCode() {
      if (!this.reference) {
        return '';
      }
      const matterNumber = this.reference.split(':')[1] || '';
      return matterNumber.split('-')[0];
    }
  }
};
</script>

<style lang="scss" scoped>
.form-values {
  padding: 0.5rem 0 0.5rem;
  .form-row {
    margin-bottom: 0.5rem;
  }
}

.label {
      font-weight: 500;
      font-size: 0.75rem;
      letter-spacing: 0.025em;
    }
</style>
