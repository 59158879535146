<template>
  <div class="wrapper">
    <RelativeDatePicker v-model="localFrom" @update:modelValue="onInput('localFrom', $event)" />
    <RelativeDatePicker v-model="localTo" @update:modelValue="onInput('localTo', $event)" />
    <div class="text">{{ dateRep.from && dateRep.from.toLocaleDateString() }} {{ dateRep.from &&
      dateRep.from.toLocaleTimeString() }}</div>
    <div class="text">{{ dateRep.to && dateRep.to.toLocaleDateString() }} {{ dateRep.to && dateRep.to.toLocaleTimeString()
    }}</div>


  </div>
</template>

<script>
import RelativeDatePicker from './RelativeDatePicker.vue';
import { convertCustomRange } from '../common/dueAt';
export default {
  components: {
    RelativeDatePicker
  },
  props: {
    from: {
      type: Object,
      default: () => null
    },
    to: {
      type: Object,
      default: () => null
    }
  },
  emits: ['input'],
  data() {
    return {
      localFrom: {
        refDate: this.from ? this.from.refDate : '',
        offset: this.from ? this.from.offset : 0,
        unit: this.from ? this.from.unit : 'day'
      },
      localTo: {
        refDate: this.to ? this.to.refDate : '',
        offset: this.to ? this.to.offset : 0,
        unit: this.to ? this.to.unit : 'day'
      }
    };
  },
  computed: {
    dateRep() {
      return convertCustomRange({
        from: this.localFrom,
        to: this.localTo
      });
    }
  },
  methods: {
    onInput(field, value) {
      this[field] = value;
      const payload = {};
      if (this.localFrom.refDate) {
        payload.from = this.localFrom;
      }
      if (this.localTo.refDate) {
        payload.to = this.localTo;
      }
      this.$emit('input', payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 5px;
  margin-top: 10px;

  .text {
    font-size: 10px;
    font-style: italic;
  }
}</style>
