<template>
  <hub-modal class="manage-filter-modal" :visible="true" :closable="true" @close="$emit('close')">
    <template #title>Manage filters</template>
    <div class="filter-modal-content">
      <div class="left-panel">
        <template v-if="!editingFilter">
          <hub-text-field v-model="searchString" class="search-input" placeholder="Search filter by name or owner" />
          <hub-button color="primary" type="button" :test-id="'create-new-filter'" @click="createNew">
            <hub-icon name="plus" />
            Create new
          </hub-button>
          <div class="filter-list">
            <div v-if="splittedFilters.selected.length" class="title">This board filters:</div>
            <div v-for="filter in splittedFilters.selected" :key="filter.id" class="filter-list-item"
              :class="{ selected: selectedFilter?.id === filter.id }" @click="onFilterSelected(filter)">
              <div class="title-wrapper" :test-id="'filter-title'">
                <hub-checkbox :value="selectedFilters.includes(filter.id)"
                  :label="`${filter.title}${filter.deleted ? ' (DELETED)' : ''}`" @change="filtersChanged(filter.id)" />
                <span class="owner">{{ filter.createdBy }}</span>
              </div>
              <div class="buttons-wrapper">
                <hub-button variant="icon" type="button" class="edit-button" @click.stop="cloneFilter(filter)">
                  <hub-icon name="content-copy" />
                </hub-button>
                <template v-if="filter.createdBy === email">
                  <hub-button variant="icon" type="button" class="edit-button" @click="startEditingFilter(filter)">
                    <hub-icon name="pencil" />
                  </hub-button>
                  <hub-button variant="icon" type="button" class="edit-button" :test-id="'delete-filter-button'"
                    @click.stop="deleteFilter(filter)">
                    <hub-icon name="delete" />
                  </hub-button>
                </template>
              </div>
            </div>

            <div v-if="splittedFilters.my.length" class="title">My filters:</div>
            <div v-for="filter in splittedFilters.my" :key="filter.id" class="filter-list-item my-filters"
              :class="{ selected: selectedFilter?.id === filter.id }" @click="onFilterSelected(filter)">
              <div class="title-wrapper">
                <hub-checkbox :value="selectedFilters.includes(filter.id)" :label="filter.title"
                  @change="filtersChanged(filter.id)" />
                <span class="owner">{{ filter.createdBy }}</span>
              </div>
              <div class="buttons-wrapper">
                <hub-button variant="icon" type="button" class="edit-button" @click.stop="cloneFilter(filter)">
                  <hub-icon name="content-copy" />
                </hub-button>
                <hub-button variant="icon" type="button" class="edit-button" @click="startEditingFilter(filter)">
                  <hub-icon name="pencil" />
                </hub-button>
                <hub-button variant="icon" type="button" :test-id="'delete-filter'" class="edit-button"
                  @click.stop="deleteFilter(filter)">
                  <hub-icon name="delete" />
                </hub-button>
              </div>
            </div>
            <div v-if="splittedFilters.others.length" class="title">Shared filters:</div>
            <div v-for="filter in splittedFilters.others" :key="filter.id" class="filter-list-item"
              @click="onFilterSelected(filter)">
              <div class="title-wrapper">
                <hub-checkbox :value="selectedFilters.includes(filter.id)" :label="filter.title"
                  @change="filtersChanged(filter.id)" />
                <span class="owner">{{ filter.createdBy }}</span>
              </div>
              <div class="buttons-wrapper">
                <hub-button variant="icon" type="button" class="edit-button" @click.stop="cloneFilter(filter)">
                  <hub-icon name="content-copy" />
                </hub-button>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div>
            <hub-button variant="text" @click="cancelEditingFilter">
              <hub-icon name="arrow-left" style="margin-right: 5px" />
              Back to filters list
            </hub-button>
          </div>
          <div class="title-wrapper">
            <hub-text-field v-model="label" :test-id="'filter-title'" label="Filter title" />
          </div>
          <hub-filter :filter="editingFilter?.data" @changed="onFilterChanged" />
        </template>
      </div>
      <div class="content" :class="{ filter: editingFilter }">
        <template v-if="!editingFilter">
          <div></div>
          <hub-list v-if="selectedFilter" :id="selectedFilter?.id" />
          <div v-else class="desc">Select filter from left panel to see preview</div>
        </template>
        <template v-else>
          <div class="filter-layout">
            <label>Layout</label>
            <ul class="filter-layout-list">
              <li class="filter-layout-list-item default" :class="{ active: selectedFilterLayout === 'default' }"
                @click="changeLayout('default')">
                <div>Title</div>
                <div>Due At</div>
                <div>Reference(s)</div>
                <div>Status</div>
              </li>
              <li class="filter-layout-list-item relative" :class="{ active: selectedFilterLayout === 'relative' }"
                @click="changeLayout('relative')">
                <div>Title</div>
                <div>Due In</div>
                <div>Reference(s)</div>
                <div>Status</div>
              </li>
              <li class="filter-layout-list-item default" :class="{ active: selectedFilterLayout === 'defaultwithnotes' }"
                @click="changeLayout('defaultwithnotes')">
                <div>Title</div>
                <div>Due at</div>
                <div>Reference(s)</div>
                <div>Status</div>
                <div style="grid-column: 1/3">Notes</div>
              </li>
              <li class="filter-layout-list-item default"
                :class="{ active: selectedFilterLayout === 'defaultwithnotesAndMilestoneTitle' }"
                @click="changeLayout('defaultwithnotesAndMilestoneTitle')">
                <div>Title - Milestone name</div>
                <div>Due At</div>
                <div>Reference(s)</div>
                <div>Status</div>
                <div style="grid-column: 1/3">Notes</div>
              </li>
              <li class="filter-layout-list-item compact" :class="{ active: selectedFilterLayout === 'compact' }"
                @click="changeLayout('compact')">
                <div>Title</div>
                <div>Reference(s)</div>
              </li>
              <li class="filter-layout-list-item minimalistic"
                :class="{ active: selectedFilterLayout === 'minimalistic' }" @click="changeLayout('minimalistic')">
                <div>Reference(s)</div>
              </li>
              <li class="filter-layout-list-item default"
                :class="{ active: selectedFilterLayout === 'defaultwithassignees' }"
                @click="changeLayout('defaultwithassignees')">
                <div>Title</div>
                <div>Due At</div>
                <div>Reference(s)</div>
                <div>Status</div>
                <div>Assignees</div>
                <div></div>
              </li>
              <li class="filter-layout-list-item default"
                :class="{ active: selectedFilterLayout === 'defaultwithassigneesandtime' }"
                @click="changeLayout('defaultwithassigneesandtime')">
                <div>Title - Mileston name</div>
                <div>Due At</div>
                <div>Reference(s)</div>
                <div>Status</div>
                <div>Assignees</div>
                <div>Time</div>
              </li>
              <li class="filter-layout-list-item four-rows"
                :class="{ active: selectedFilterLayout === 'defaultwithallassigneesandtime' }"
                @click="changeLayout('defaultwithallassigneesandtime')">
                <div>Title - Mileston name</div>
                <div>Due At</div>
                <div>Reference(s)</div>
                <div>Status</div>
                <div>Task and Milestone assignees</div>
                <div>Time</div>
                <div style="grid-column: 1/3">Notes</div>
              </li>
            </ul>
          </div>
          <div v-if="isGetCollectionRequestPending" style="place-self: center">
            <hub-icon name="loading" spin size="lg"></hub-icon>
          </div>
          <div v-else class="filter-preview">
            <hub-task-list :selectable="false" :collection="collection" :skip="0" :total="total" :size="total"
              :layout="layout" />
          </div>
        </template>
      </div>
    </div>
    <template #footer>
      <div class="manage-filter-modal-footer">
        <template v-if="editingFilter">
          <hub-button :disabled="isCreateRequestPending" style="justify-self: start" type="button"
            :test-id="'save-filter-button'" color="primary" @click="saveFilter">
            {{ editingFilter.id ? 'Update' : 'Create' }}
          </hub-button>
        </template>
        <template v-else>
          <hub-button type="button" @click="$emit('close')">Close</hub-button>
          <hub-button v-if="hasChanges" style="justify-self: start" type="button" color="primary" :test-id="'save-button'"
            @click="onSaveOrder">Save
          </hub-button>
        </template>
      </div>
    </template>
  </hub-modal>
</template>
<script>
import { mapState } from 'vuex';
import Modal from '@/components/common/Modal';
import Checkbox from '@/components/common/Checkbox';
import Button from '@/components/common/Button';
import TextField from '@/components/common/TextField';
import Icon from '@/components/common/Icon';
import Filter from './Filter.vue';
import dueAt, { convertCustomRange } from '@/components/common/dueAt';

import ListWithAutoload from '../ListWithAutoload';
import List from '@/components/search/ListWithPaging';

export default {
  components: {
    'hub-modal': Modal,
    'hub-checkbox': Checkbox,
    'hub-button': Button,
    'hub-list': ListWithAutoload,
    'hub-text-field': TextField,
    'hub-icon': Icon,
    'hub-filter': Filter,
    'hub-task-list': List
  },
  props: {
    selected: {
      type: Array,
      default: () => []
    },
    filterToEdit: {
      type: String,
      default: null
    }
  },
  emits: ['close', 'filtersListChanged', 'filterUpdated'],
  data() {
    const selectedFilters = this.selected.reduce((acc, curr) => {
      acc.push(...curr);
      return acc;
    }, []);
    return {
      selectedFilters,
      selectedFilter: this.filterToEdit,
      searchString: '',
      editingFilter: null,
      filterBeforeEdit: null,
      title: null,
      layout: null,
      label: ''
    };
  },
  computed: {
    ...mapState({
      filters: s => s.filters.collection,
      email: s => s.identity.email,
      collection: s => s.filters.tasks.collection,
      total: s => s.filters.tasks.total,
      skip: s => s.filters.tasks.skip,
      size: s => s.filters.tasks.size,
      isGetCollectionRequestPending: s => s.filters.tasks.isGetCollectionRequestPending,
      isCreateRequestPending: s => s.filters.isUpdateRequestPending || s.filters.isCreateRequestPending
    }),
    filteredList() {
      const search = this.searchString
        ?.toLowerCase()
        ?.split(' ')
        ?.filter(e => e);

      if (!search?.length) {
        return this.filters;
      }

      return this.filters.filter(
        f =>
          search.every(s =>
            f.title
              .toLowerCase()
              .split(' ')
              .find(word => word.startsWith(s))
          ) || search.every(s => f.createdBy.toLowerCase().startsWith(s))
      );
    },
    splittedFilters() {
      return this.filteredList.reduce(
        (acc, curr) => {
          if (this.selectedFilters.includes(curr.id)) {
            acc.selected.push(curr);
          } else if (curr.createdBy === this.email && !curr.deleted) {
            acc.my.push(curr);
          } else if (!curr.deleted) {
            acc.others.push(curr);
          }
          return acc;
        },
        { my: [], others: [], selected: [] }
      );
    },
    hasChanges() {
      if (this.selected.length !== this.selectedFilters.length) {
        return true;
      }
      return this.selectedFilters.some(f => !this.selected.includes(f));
    },
    selectedFilterLayout() {
      return this.layout || this.editingFilter.layout || 'default';
    }
  },
  watch: {
    selectedFilter: {
      async handler(a, b) {
        if (JSON.stringify(a) === JSON.stringify(b)) {
          return;
        }
        await this.refreshPreview(a?.data);
      },

      deep: true,
      immediate: true
    },
    editingFilter: {
      async handler(a, b) {
        if (JSON.stringify(a) === JSON.stringify(b)) {
          return;
        }
        await this.refreshPreview(a?.data);
      },

      deep: true,
      immediate: true
    }
  },
  async created() {
    if (this.filteredList.length && this.filterToEdit) {
      const filter = this.filteredList.find(f => f.id === this.filterToEdit);

      if (filter) {
        this.startEditingFilter(filter);
        this.onFilterSelected(filter);
      }
    }
    this.$store.dispatch('tasks/getStatusCollection', {
      assignees: [],
      size: 0
    });
  },
  methods: {
    filtersChanged(id) {
      const index = this.selectedFilters.findIndex(f => f === id);
      if (index > -1) {
        this.selectedFilters.splice(index, 1);
      } else {
        this.selectedFilters.push(id);
      }
    },
    onFilterSelected(filter) {
      if (this.selectedFilter?.id === filter.id) {
        this.selectedFilter = null;
      } else {
        this.selectedFilter = filter;
      }
    },
    async deleteFilter(filter) {
      const confirmResult = await this.$confirm({
        title: 'Delete filter?',
        message: `Are you sure you want to delete filter '${filter.title}'?\n\nThis action can't be undone.`,
        confirm: 'Delete'
      });
      if (!confirmResult) {
        return;
      }

      await this.$store.dispatch('filters/delete', filter.id);

      const index = this.selectedFilters.findIndex(id => id === filter.id);
      if (index > -1) {
        this.selectedFilters.splice(index, 1);
        const payload = this.selected.reduce((acc, curr) => {
          const arr = curr.filter(f => f !== filter.id);
          if (arr && arr.length) {
            acc.push(arr);
          }
          return acc;
        }, []);
        this.$emit('filtersListChanged', payload);
      }
    },
    async onFilterChanged(filter) {
      this.editingFilter.data = {
        qs: filter.qs,
        status: filter.status,
        assignees: filter.assignees,
        assigneesConjugation: filter.assigneesConjugation || 'or',
        tagsConjugation: filter.tagsConjugation || 'or',
        tags: filter.tags,
        projectAssignees: filter.projectAssignees,
        dueAt: filter.dueAt,
        workflows: filter.workflows,
        catchAll: filter.catchAll
      };

      await this.refreshPreview(this.editingFilter.data);
    },

    createCatchAllCondition() {
      const otherFilters = this.selectedFilters.filter(id => id !== this.selectedFilter.id);
      return otherFilters.map(id => {
        const filter = this.filters.find(filter => filter.id === id);
        return filter ? filter.data : {};
      });
    },
    async refreshPreview(context) {
      if (!context) {
        return;
      }

      let exclude = [];

      if (context.catchAll) {
        exclude = this.createCatchAllCondition();
      }
      let from, to;
      const dueAtValue = context.dueAt;
      if (typeof dueAtValue === 'string') {
        const settings = dueAt().find(item => item.name === context.dueAt) || {};
        from = settings.from;
        to = settings.to;
      } else if (dueAtValue && (dueAtValue.from || dueAtValue.to)) {
        const convertResult = convertCustomRange(dueAtValue);
        from = convertResult.from;
        to = convertResult.to;
      }

      const dueAtArr = [];
      if (from) {
        dueAtArr.push('from:' + from.toISOString());
      }
      if (to) {
        dueAtArr.push('to:' + to.toISOString());
      }
      this.$store.dispatch('filters/preview', {
        qs: context.qs?.length ? context.qs : undefined,
        status: context.status?.length ? context.status : undefined,
        assignees: context.assignees?.length ? context.assignees.filter(a => a) : undefined,
        projectAssignees: context.projectAssignees?.length ? context.projectAssignees : undefined,
        assigneesConjugation: context?.assigneesConjugation ? context?.assigneesConjugation : 'or',
        dueAt: dueAtArr?.length ? dueAtArr : undefined,
        workflows: context.workflows ? context.workflows : undefined,
        tags: context.tags || [],
        tagsConjugation: context?.tagsConjugation || 'or',
        size: 50,
        exclude
      });
    },
    async changeLayout(layout) {
      this.layout = layout;
    },
    createNew() {
      this.editingFilter = {};
      this.filterBeforeEdit = {};
      this.selectedFilter = null;
    },
    async saveFilter() {
      const obj = {
        title: this.label.trim(),
        layout: this.selectedFilterLayout,
        data: this.editingFilter.data || {}
      };

      if (this.editingFilter.id) {
        await this.$store.dispatch('filters/update', { id: this.editingFilter.id, ...obj });
        this.$emit('filterUpdated', this.editingFilter.id);
        await this.refreshPreview(this.editingFilter.data);
      } else {
        const item = await this.$store.dispatch('filters/create', { ...obj });
        this.selectedFilters.push(item.id);
      }
      this.$trackEvent(`Filter ${this.editingFilter.id ? 'updated' : 'created'}`);
      this.cancelEditingFilter();

      if (this.filterToEdit) {
        this.$emit('close');
      }
    },
    async cloneFilter(filter) {
      const { title, layout, data } = filter;
      await this.$store.dispatch('filters/create', { title: `${title}(Clone)`, layout, data });
      this.$trackEvent('Filter created');
    },
    async startEditingFilter(filter) {
      this.editingFilter = filter;
      this.filterBeforeEdit = JSON.parse(JSON.stringify(filter));
      this.label = filter.title;
      this.layout = filter.layout;
    },
    cancelEditingFilter() {
      this.editingFilter.data = this.filterBeforeEdit.data;
      this.editingFilter.title = this.filterBeforeEdit.title;
      this.editingFilter.selectedFilterLayout = this.filterBeforeEdit.selectedFilterLayout;

      this.filterBeforeEdit = null;
      this.editingFilter = null;
      this.layout = null;
      this.label = null;
    },
    onSaveOrder() {
      const initialFlattened = this.selected.reduce((acc, curr) => {
        acc.push(...curr);
        return acc;
      }, []);
      const added = this.selectedFilters.filter(f => !initialFlattened.includes(f));
      const removed = initialFlattened.filter(f => !this.selectedFilters.includes(f));
      const result = this.selected.reduce((acc, curr) => {
        const filtered = curr.filter(f => !removed.includes(f));
        if (filtered && filtered.length) {
          acc.push(filtered);
        }
        return acc;
      }, []);
      added.forEach(f => result.push([f]));
      result.push([]);
      this.$emit('filtersListChanged', result);
    }
  }
};
</script>
<style lang="scss">
.manage-filter-modal {
  .filter-modal-content {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: minmax(400px, 1fr) minmax(0, 2fr);
    width: 100%;
    height: 100%;
    padding: 1rem;

    .left-panel {
      height: 100%;
      overflow: hidden;
      display: grid;
      grid-template-rows: max-content max-content 1fr;
      grid-gap: 10px;
      padding: 0.5rem;
      border-radius: 2px;
      background: var(--theme-surface);

      .title-wrapper {
        padding: 0.5rem;
      }

      .filter-wrapper {
        overflow-y: scroll;
      }

      .filter-list {
        overflow-y: scroll;
        padding: 5px;

        .title {
          font-size: 1.2rem;
          // margin-bottom: 20px;
        }

        .filter-list-item {
          border: 1px solid var(--theme-on-surface-accent);
          background: var(--theme-background);
          margin-bottom: 5px;
          border-radius: 5px;
          display: flex;
          justify-content: space-between;

          .title-wrapper {
            display: flex;
            flex-direction: column;

            .owner {
              margin: 5px;
              font-size: 0.7rem;
            }
          }

          .buttons-wrapper {
            display: none;
          }

          &:hover {
            background-color: var(--theme-highlight);
            border-color: var(--theme-highlight);

            .buttons-wrapper {
              display: block;
            }
          }

          &.selected {
            background-color: var(--theme-primary);
            border-color: var(--theme-primary);
            color: var(--theme-on-primary);
            cursor: default;
          }
        }
      }
    }

    .content {
      height: 100%;
      overflow: hidden;

      &.filter {
        display: grid;
        grid-template-rows: max-content 1fr;
      }

      .desc {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: italic;
        font-size: 0.75rem;
      }

      .filter-layout {
        padding-right: var(--theme-scroll-width);
        display: grid;
        grid-template-rows: max-content max-content;
        grid-gap: 5px;

        label {
          font-weight: 500;
          font-size: 0.75rem;
          letter-spacing: 0.025em;
        }

        .filter-layout-list {
          background-color: var(--theme-surface);
          padding: 0.5rem;
          display: grid;
          grid-template-columns: 200px 200px 200px 200px 200px 200px 200px 200px 250px 1fr;
          grid-gap: 0.5rem;
          width: 100%;
          overflow-x: scroll;
        }

        .filter-layout-list-item {
          border-radius: 3px;
          border: 1px solid transparent;
          padding: 0.25rem;
          display: grid;
          grid-gap: 0.25rem;
          transition: 0.25s box-shadow;

          // box-shadow: 0 0 0 1px var(--theme-highlight);
          &:hover {
            cursor: pointer;
            // border-color: var(--theme-on-surface);
            box-shadow: 0 0 0 1px var(--theme-highlight);
          }

          &.active {
            border-color: var(--theme-on-surface);
          }

          &.default,
          &.relative {
            grid-template-columns: minmax(0, 1fr) max-content;
            grid-template-rows: max-content max-content;
          }

          &.four-rows {
            grid-template-columns: minmax(0, 1fr) max-content;
            grid-template-rows: max-content max-content max-content max-content;
          }

          &.full-row {
            column-span: all;
          }

          &.minimalistic {
            align-items: flex-start;
            justify-content: unset;
            height: fit-content;
          }

          >div {
            background: var(--theme-highlight);
            padding: 0.15rem 0.5rem;
            font-size: 0.75rem;
            color: var(--theme-on-surface-accent);
            font-weight: 500;
            border-radius: 3px;
            font-style: italic;
            border-radius: 3px;
          }
        }
      }

      .filter-preview {
        overflow-y: scroll;
        padding-top: 0.25rem;
      }
    }
  }

  .manage-filter-modal-footer {
    grid-column: 1/3;
    grid-row: 4/5;
    place-self: flex-end;

    >* {
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }

  .modal {
    min-width: 80%;
    min-height: 90%;
    grid-template-rows: max-content minmax(0, 1fr) max-content;

    >header {
      padding: 1rem calc(1rem + var(--theme-scroll-width));
    }
  }

  form {
    background: transparent !important;
  }
}
</style>
