<template>
  <div>
    <hub-modal :visible="!!selected" class="task-modal" closable @close="onCreateTaskModalClosed">
      <template #title>{{ selected.title }}</template>
      <template #actions>
        <!-- <Button
          v-if="hasChanged"
          :disabled="isUpdateRequestPending"
          type="button"
          variant="icon"
          title="Save"
          :test-id="'save-button'"
          @click.prevent="save"
          ><Icon name="content-save"></Icon
        ></Button> -->
        <Button
          :disabled="isUpdateRequestPending"
          type="button"
          variant="icon"
          :title="silentUpdate ? `Don't notify assignees about changes` : 'Notify assignees about changes'"
          :test-id="'bell'"
          @click.prevent="toggleSilence"
          ><Icon :name="silentUpdate ? 'bell-off' : 'bell'"></Icon
        ></Button>
      </template>
      <hub-edit-task
        ref="editTaskRef"
        :data="selected"
        :silent="silentUpdate"
        @edited="e => $emit('edited', e)"
        @close="onCreateTaskModalClosed"
        @changed="onChanged"
      />
    </hub-modal>
    <hub-modal :visible="!!taskCreateOptions" class="task-modal" closable @close="onCreateTaskModalClosed">
      <template #title>Create task</template>
      <hub-create-task :data="taskCreateOptions" @close="onCreateTaskModalClosed" />
    </hub-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Modal from './../../common/Modal';
import EditTask from './../../inventions/tasks/EditTask';
import CreateTask from './../../inventions/tasks/CreateTask';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';

export default {
  components: {
    'hub-modal': Modal,
    'hub-create-task': CreateTask,
    'hub-edit-task': EditTask,
    Button,
    Icon
  },
  props: {
    selected: {
      type: Object,
      default: () => null
    },
    taskCreateOptions: {
      type: Object,
      default: () => null
    }
  },
  emits: ['close', 'createModalClose', 'edited'],

  data() {
    return {
      silentUpdate: false,
      hasChanged: false
    };
  },
  computed: {
    ...mapState({
      isUpdateRequestPending: s => s.tasks.isUpdateRequestPending
    })
  },
  methods: {
    onCreateTaskModalClosed(event) {
      this.silentUpdate = false;
      this.hasChanged = false;
      if (event) {
        this.$emit('createModalClose', event);
      } else {
        this.$emit('close');
      }
    },
    save() {
      this.$refs.editTaskRef.save();
    },
    toggleSilence() {
      this.silentUpdate = !this.silentUpdate;
    },
    onChanged(value) {
      this.hasChanged = value;
    }
  }
};
</script>

<style lang="scss">
.task-modal {
  z-index: 500;
  .modal {
    height: 90%;
    width: 1024px;
    grid-template-rows: max-content minmax(0, 1fr) max-content;
  }
  .modal > div {
    padding: 0.5rem 2rem 0.5rem 2rem;
  }
  form {
    background: transparent !important;
  }
}
</style>
