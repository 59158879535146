<template>
  <div class="formpicker-wrapper">
    <Multiselect
      v-model:value="localValue"
      :disabled="disabled"
      :multiple="true"
      :taggable="disabled"
      :label="label"
      :options="mergedForms"
      @input="emitChanges"
    />
    <template v-if="!disabled">
      <Button v-if="!loading" variant="icon" @click="isModalVisible = true">
        <Icon name="form-select" />
      </Button>
      <Icon v-else name="loading" spin />
    </template>
    <FormPickerModal
      v-if="isModalVisible"
      :value="localValue"
      :all="allForms"
      :client-specific="clientSpecificForms"
      :organization="organization"
      @close="isModalVisible = false"
      @submit="onSubmit"
    />
  </div>
</template>

<script>
import Multiselect from '@/components/common/Multiselect';
import httpClient from '@/utils/httpClient';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import FormPickerModal from './FormPickerModal.vue';
export default {
  components: { Multiselect, Button, Icon, FormPickerModal },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: null
    },
    clientCode: {
      type: String,
      default: null
    },
    tags: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:value'],
  data() {
    return {
      localValue: this.value ? [...this.value] : [],
      clientSpecificForms: [],
      allForms: [],
      organization: null,
      isModalVisible: false,
      loading: false
    };
  },
  computed: {
    mergedForms() {
      return [...this.allForms, ...this.clientSpecificForms].reduce((acc, curr) => {
        if (!acc.find(f => f.id === curr.id)) {
          acc.push(curr);
        }
        return acc;
      }, []);
    }
  },
  watch: {
    value() {
      this.localValue = [...this.value];
    }
  },
  async created() {
    this.loading = true;
    const [clientSpecificForms, allForms, organization] = await Promise.all([
      httpClient.get(`/api/template-storage/forms?clients=${this.clientCode}`),
      httpClient.get(`/api/template-storage/forms?clients=all`),
      httpClient.get(`/api/fip-connector/organizations/${this.clientCode}`)
    ]);
    this.organization = organization.name;
    this.allForms = allForms.data
      .filter(form => {
        if (this.disabled) {
          return true;
        }
        if (form.tags && this.tags && this.tags.length) {
          return form.tags.some(t => this.tags.includes(t));
        }
        return true;
      })
      .map(f => ({ ...f, label: f.name, value: f.id }));
    this.clientSpecificForms = clientSpecificForms.data
      .filter(form => {
        if (this.disabled) {
          return true;
        }
        if (form.tags && this.tags && this.tags.length) {
          return form.tags.some(t => this.tags.includes(t));
        }
        return true;
      })
      .map(f => ({ ...f, label: f.name, value: f.id }));
    this.loading = false;
  },
  methods: {
    onSubmit(e) {
      this.localValue = e;
      this.isModalVisible = false;
      this.emitChanges(e);
    },
    emitChanges(e) {
      this.$emit('update:value', e);
    }
  }
};
</script>

<style lang="scss" scoped>
.formpicker-wrapper {
  display: grid;
  grid-template-columns: 1fr min-content;
  button {
    margin-top: 20px;
  }
}
</style>
