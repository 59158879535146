<template>
  <Loading v-if="isRequestPending" />
  <hub-edit-task-form
    v-else
    ref="taskRef"
    :key="item.id"
    :is-request-pending="isUpdateRequestPending"
    :is-request-failed="isUpdateRequestFailed"
    :item="item"
    :invention="invention"
    :template-collection="templateCollection"
    :error-message="errorMessage"
    :transitions="currentTransitions"
    :workflow="data.workflow"
    :instructions="instructions"
    @changed="onChange"
    @submit="update"
    @close="$emit('close')"
  />
</template>

<script>
/* eslint-disable vue/one-component-per-file */

import { createApp, h } from 'vue';
import { mapState } from 'vuex';
import Edit from './_CreateOrEdit';
import MetadataModal from './MetadataForm';
import Loading from '@/components/common/Loading';
import ToastService from '@/plugins/ToastService';
import { statusChangeToText } from '@/utils/statusChangeToText';

export default {
  components: {
    'hub-edit-task-form': Edit,
    Loading
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    silent: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'edited', 'changed'],
  data() {
    return {
      isRequestPending: false,
      errorMessage: null
    };
  },
  computed: {
    ...mapState({
      identity: s => s.identity,
      item: s => s.tasks.item,
      invention: s => s.tasks.invention,
      templateCollection: s => s.tasks.templateCollection,
      isGetRequestPending: s => s.tasks.isGetRequestPending,
      isGetTemplateCollectionPending: s => s.tasks.isGetTemplateCollectionPending,
      isUpdateRequestPending: s => s.tasks.isUpdateRequestPending,
      isUpdateRequestFailed: s => s.tasks.isUpdateRequestFailed,
      step: s => s.steps.item,
      milestoneTemplates: s => s.milestones.templates,
      bots: s => s.bots
    }),
    currentTransitions() {
      if (this.step) {
        const workflowTemplate = this.milestoneTemplates[this.item.workflow.id];
        const milestoneTemplate = workflowTemplate?.template.find(template => template.id === this.item.workflow.templateId);
        return this.step.step.transitions
          .filter(t => t.current === this.item.status)
          .map(t => {
            const [userLabel, botLabel] = t.label.split('|');
            const assignees = this.item.assignees || [];
            let bot = assignees.find(assignee => this.bots.includes(assignee));
            let botName = bot && bot.split('@')[0];
            const isMyTask = assignees.includes(this.identity.email);
            const isBotTask = bot && isMyTask && t.next === 'in progress';
            t.label = userLabel;
            if (botLabel && isBotTask) {
              t.label = `${botLabel} ${botName}`;
            }
            const statusText = statusChangeToText(milestoneTemplate, this.item.workflow.stepId, t.next, true, isBotTask);

            return { ...t, statusText };
          });
      } else {
        const currentTemplate = this.templateCollection.find(t => t.type === 'generic');
        return currentTemplate?.transitions?.filter(t => t.current === this.item.status);
      }
    },
    instructions() {
      return this.item?.workflow?.stepId && this.step?.step?.options?.instructions ? this.step?.step?.options?.instructions : '';
    }
  },

  watch: {
    'data.id': {
      async handler() {
        try {
          this.isRequestPending = true;

          await this.$store.dispatch('tasks/getById', this.data.id);

          if (!this.item) {
            return;
          }

          if (this.item.workflow && this.item.workflow.stepId) {
            await this.$store.dispatch('steps/getById', {
              workflowId: this.item.workflow.id,
              milestoneId: this.item.workflow.milestoneId,
              stepId: this.item.workflow.stepId
            });
          }
          await Promise.all([
            this.$store.dispatch('tasks/getInventionById', { inventionId: this.item.inventionId }),
            this.$store.dispatch('tasks/getTemplateCollection', { inventionId: this.item.inventionId })
          ]);
        } catch (error) {
          this.$toast.error({
            title: 'Failed to open task',
            message: `Please, try again later or contact our development team`
          });

          this.$emit('close');
          return;
        } finally {
          this.isRequestPending = false;
        }
      },
      immediate: true
    }
  },
  methods: {
    onChange(value) {
      this.$emit('changed', value);
    },
    async save() {
      this.$refs.taskRef.submit(this.item.status);
    },
    async update(data) {
      try {
        this.isRequestPending = true;
        this.errorMessage = null;
        let metadata = null;
        let form;
        let formData;
        if (this.item.status !== data.status) {
          if (data.workflow.stepId) {
            await this.$store.dispatch('steps/getById', {
              workflowId: this.data.workflow.id,
              milestoneId: this.data.workflow.milestoneId,
              stepId: this.data.workflow.stepId
            });
            form = this.step?.out && this.step.out.find(s => s.from && s.from.port === data.status)?.from?.action?.form;
            if (form && form.properties?.length) {
              const formValues = await this.$store.dispatch('steps/getForm', {
                template: form.properties,
                model: { thisTask: this.item },
                context: {
                  inventionId: this.item.inventionId,
                  milestoneId: this.item.workflow.milestoneId
                }
              });

              metadata = await this.requestMetadata({ title: form.name, properties: formValues });
              if (!metadata) {
                return;
              }

              formData = {
                name: form.name,
                properties: metadata
              };
            }
          }
        }

        const prevStatus = this.item.status;
        await this.$store.dispatch('tasks/update', {
          ...data,
          id: this.data.id,
          updatedAt: this.item.updatedAt,
          formData: formData || {},
          skipNotification: this.silent
        });

        this.$toast.success({
          title: 'Update completed',
          message: `Task was updated.`
        });

        this.$emit('edited', { id: this.data.id });
        this.$emit('close', {});
      } catch (e) {
        if (e.response?.status === 409) {
          this.errorMessage = `Task has been already modified by another user. Please, refresh it and try again.`;

          this.$toast.error({
            title: 'Update conflict',
            message: `Task has been already modified by another user. Please, refresh it and try again.`
          });
        } else {
          this.$toast.error({
            title: 'Update failed',
            message: `Please, check your input, try again later or contact our development team.`
          });
        }
      } finally {
        this.isRequestPending = false;
      }
    },
    async requestMetadata(form) {
      const that = this;
      return new Promise(resolve => {
        const closeWithResult = result => {
          instance.unmount();
          resolve(result);
        };
        const instance = createApp({
          render() {
            return h(MetadataModal, {
              form,
              task: that.data,
              emitter: that.emitter,
              onClose() {
                closeWithResult();
              },
              onSubmit(result) {
                closeWithResult(result);
              }
            });
          }
        });
        instance.use(that.$store);
        instance.use(ToastService);
        instance.mount(document.getElementById('modal'));
      });
    }
  }
};
</script>
