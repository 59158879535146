export default (status, botTask) => {
  if (botTask) {
    return 'robot';
  }
  switch (status) {
    case 'done':
      return 'check-circle-outline';
    case 'rejected':
      return 'close-circle-outline';
    case 'in progress':
      return 'progress-clock';
    case 'sent to 2AR':
      return 'numeric-2-circle-outline';
    case 'air':
      return 'message';
    case 'cancelled':
      return 'cancel';
    case 'blocked':
      return 'stop-circle-outline';
    case 'approve':
    case 'approve with comments':
      return 'alpha-a-circle-outline';
    case 'on hold':
      return 'pause-circle-outline';
    case 'to do':
      return 'clipboard-text-play';
    default:
      return '';
  }
};
