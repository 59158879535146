<template>
  <hub-modal :visible="true" closable @close="$emit('close')">
    <template #title>Move {{ selectedReady.length }} {{ selectedReady.length === 1 ? 'task' : 'tasks' }} to {{ status }}</template>
    <div class="modal-content">
      <template v-if="sortedList.blocked.length">
        <div class="block-title">
          Tasks that require individual attention:
        </div>
        <div v-for="task in sortedList.blocked" :key="task.id" class="task-row">
          <ReferenceList :invention="{ id: task.invention.id, references: task.invention.references, title: 'Open Invention' }" />
          {{ task.title }}
        </div>
      </template>
      <template v-if="sortedList.ready.length">
        <div v-if="sortedList.blocked.length" class="block-title">Tasks ready for status change:</div>
        <div v-for="task in sortedList.ready" :key="task.id" class="task-row">
          <Checkbox :value="selected.includes(task.id)" @change="toggleTask(task.id)" />
          <ReferenceList class="move-top" :invention="{ id: task.invention.id, references: task.invention.references, title: 'Open Invention' }" />
          <span class="move-top">
            {{ task.title }}
          </span>
        </div>
      </template>
    </div>
    <template #footer>
      <div class="footer-buttons">
        <Button variant="text" @click="$emit('close')">Cancel</Button>
        <Button :disabled="!selectedReady.length" style="justify-self: start" type="button" color="primary" @click="onSubmit"
          >Move {{ selectedReady.length }} to {{ status }}</Button
        >
      </div>
    </template>
  </hub-modal>
</template>
<script>
import { mapState } from 'vuex';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import ReferenceList from '@/components/common/ReferenceList.vue';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    'hub-modal': Modal,
    Button,
    Checkbox,
    ReferenceList
  },
  props: {
    status: {
      type: String,
      required: true
    },
    tasks: {
      type: Array,
      required: true
    }
  },
  emits: ['taskRemoved', 'close', 'submit'],
  data() {
    return {
      selected: this.tasks.map(t => t.id)
    };
  },
  computed: {
    ...mapState({
      milestoneTemplates: s => s.milestones.templates
    }),
    sortedList() {
      return this.tasks.reduce(
        (acc, curr) => {
          let path = null;
          if (curr.workflow) {
            const workflowTemplate = this.milestoneTemplates[curr.workflow.id];
            if (workflowTemplate && workflowTemplate.template) {
              const milestoneTemplate = workflowTemplate.template.find(template => template.id === curr.workflow.milestoneTemplateId);
              if (milestoneTemplate) {
                path = milestoneTemplate.paths.find(path => path.from.step === curr.workflow.stepId && path.from.port === this.status);
              }
            }

            // return transitions.map(t => ({ label: t.label, key: t.next, icon: findStatusIcon(t.next) }));
          }
          if (path && path.from && path.from.action && path.from.action.form) {
            acc.blocked.push(curr);
          } else {
            acc.ready.push(curr);
          }
          return acc;
        },
        {
          ready: [],
          blocked: []
        }
      );
    },
    selectedReady() {
      return this.selected.filter(id => this.sortedList.ready.find(t => t.id === id));
    }
  },
  methods: {
    toggleTask(id) {
      const index = this.selected.indexOf(id);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(id);
      }
    },
    onSubmit() {
      this.$emit(
        'submit',
        this.sortedList.ready.filter(t => this.selectedReady.includes(t.id))
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-content {
  padding: 20px;
  .block-title {
    margin-bottom: 10px;
  }
  .move-top {
    margin-bottom: 3px;
  }
  .task-row {
    display: grid;
    grid-template-columns: max-content max-content max-content max-content;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
  }
}
</style>
